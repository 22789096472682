import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useApi } from './api';

const ContentContext = React.createContext(undefined);

export function ContentProvider(props) {
	const [ plants, setPlants ] = useState();
	const [ enums, setEnums ] = useState();
	const { gardenAPI } = useApi();

	let reloadContent = useCallback(() => {
		if (!gardenAPI) return;
		gardenAPI.getAll().then(res => {
			if (!res) return;
			setPlants(res.plants)
			let enums = {}
			for (let e of res.enums) {
				enums[e.name] = e.values
			}
			setEnums(enums)
		})
	}, [ gardenAPI ])

	useEffect(() => {
		reloadContent();
	}, [ reloadContent ])

	return <ContentContext.Provider value={{ plants, enums, reloadContent }}>
		{props.children}
	</ContentContext.Provider>;
}

export function useContent() {
	const { plants, enums, reloadContent } = useContext(ContentContext);
	return { plants, enums, reloadContent };
}
