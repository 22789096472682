import { Menu, theme, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { useContent } from 'contexts/content';
import strings from 'strings';
import './style.scss';
import schema from 'content';

const { Header } = Layout;




export const AppHeader = () => {
	const {
	    token: { colorBgContainer },
	} = theme.useToken();

	const nav = [
		{
			key: 'explore',
			label: <Link to="/explore" >{strings.explore}</Link>
		},
		{
			key: 'enums',
			label: strings.enums,
			children: schema.filter(f => f.type.name === 'enum').map(f => ({
				key: f.code,
				label: <Link to={`/enum/${f.code}`}>{strings[f.title]}</Link>
			}))
		},
		{
			key: 'chat',
			label: <Link to="/chat" >{strings.chat}</Link>
		},
	]

	
	return <Header style={{ backgroundColor: '#073039' }}>

			<Link className='header-logo' to='/'>
				<img className='header-logo-img' src='https://ik.imagekit.io/f7wbs7axze/bg_logo_DdRDaOrcI.png'/>
				{strings.brightGarden}
			</Link>
	
		<Menu
			theme='dark'
			style={{ backgroundColor: '#073039' }}
			mode="horizontal"
			defaultSelectedKeys={['2']}
			items={nav}
       	/>
	</Header>
}
