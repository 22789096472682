import { useState, useEffect, useRef } from 'react';
import { useContent } from 'contexts/content';
import { useParams } from 'react-router-dom';
import { notif } from 'components/notification';
import { Input, Button, Card } from 'antd';
import { useApi } from 'contexts/api'
import strings from 'strings';
import schema from 'content'
import { SendOutlined } from '@ant-design/icons'
import * as dayjs from 'dayjs';


import './style.scss';

const { TextArea } = Input;



const BotTyping = () => {
	const [ dots, setDots ] = useState('...')

	useEffect(() => {
		let timeoutId = setTimeout(function() {
			if (dots.length === 3) {
				setDots('.');
			} else {
				setDots(dots + '.')
			}
		}, 400);
		return () => {
			clearTimeout(timeoutId)
		}
	}, [ dots ])

	useEffect(() => {
	}, [])



	return <div className='chat-typing-text'>{strings.botTyping}{dots}</div>;
}

const Message = (props) => {
	let { message } = props;

	return <div className={`message ${message.role}`}>
		{/*<div className='message-avatar'></div>*/}
		{/*<div className='message-time'>{dayjs.unix(message.time / 1000).format('MMMM D, YYYY HH:mm')}</div>*/}
		<Card className='message-body'>
			{message.content}
		</Card>
	</div>
}

export const ChatPage = () => {
	const defaultMessage = {
		time: Date.now(),
		role: 'assistant',
		content: strings.defaultBotMessage,
	};

	const { gardenAPI } = useApi();
	const [ messages, setMessages ] = useState([ defaultMessage ]);
	const [ currentMessage, setCurrentMessage ] = useState();
	const [ pendingMessage, setPendingMessage ] = useState();
	const messagesEndRef = useRef();


	useEffect(() => {
		if (!gardenAPI) return;
		gardenAPI.chat().get().then(res => {
			if (res.length > 0) {
				defaultMessage.time = res[0].time;
			}
			setMessages([defaultMessage].concat(res));
		});
	}, [ gardenAPI ])

	useEffect(() => {
		let timeout = setTimeout(() => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' }, 100));
		return () => clearTimeout(timeout);
	}, [ messages, pendingMessage ])

	const send = () => {
		setPendingMessage({
			role: 'user',
			content: currentMessage,
			time: Date.now(),
		});
		setCurrentMessage();
		gardenAPI.chat().send(currentMessage).then(res => {
			setPendingMessage();
			setMessages([ ...messages, ...res]);
		})
	}

	const clear = () => {
		gardenAPI.chat().archive().then(res => {
			setMessages([defaultMessage]);
		})
	}

	return <div className='chat-container'>
		<div className='messages-container'>
			{messages.map((msg, index) => <Message key={index} message={msg}/>)}
			{pendingMessage && <Message message={pendingMessage}/>}
			{pendingMessage && <BotTyping/>}
			<div ref={messagesEndRef} />
		</div>
		<div className='chat-panel'>
			<Input.TextArea 
				onPressEnter={send}
				value={currentMessage}
				placeholder={strings.messagePlaceholder} 
				onChange={e => setCurrentMessage(e.target.value)}
			/>
			<Button 
				onClick={send} 
				className='chat-send-button' 
				size='large' 
				icon={<SendOutlined/>} 
				type='primary'
			>
				{strings.chatSend}
			</Button>
			<Button 
				style={{ right: '0px' }}
				type='ghost'
				onClick={clear} 
				className='chat-clear-button' 
			>
				{strings.chatClear}
			</Button>
		</div>
	</div>
}