import { DatePicker } from 'antd';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import * as dayjs from 'dayjs'

dayjs.locale('ru')

export const ValueRender = (props) => {
	const format = 'MMMM Do YYYY'
	let defaultValue = props.defaultValue && dayjs.unix(props.defaultValue);
	if (!props.onChange) return <>{defaultValue && defaultValue.format(format)}</>;
	
	const onChange = (m) => {
		props.onChange && props.onChange(m.unix());
	};

	return (<DatePicker
		locale={locale}
		format={format}
		defaultValue={defaultValue}
		onChange={onChange}
		width={400}
	/>);
}

export const DateField = {
	render: ValueRender,
	default: () => Math.floor(Date.now() / 1000),
}