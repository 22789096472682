import React from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';

import { Layout, Menu} from 'antd';

import { Plants } from './pages/plants';
import { EnumPage } from './pages/enum';
import { ChatPage } from './pages/chat';

import { ApiProvider } from './contexts/api';
import { UserProvider } from './contexts/user';
import { HotkeyProvider } from './contexts/hotkey';
import { ContentProvider } from './contexts/content';
import { IKContext } from 'imagekitio-react';

import { AppHeader } from 'components/appHeader';

import strings from 'strings';

import './style.css';
import { mainColor } from 'theme';

const { Header, Content, Footer } = Layout;

strings.setLanguage('ru'); // TODO

export default function App() {
	return <ConfigProvider
		theme={{
			token: {
				colorText: '#073039',
				colorPrimary: '#c46e65',
				colorBgContainer: '#ecf6fd'
			}
		}}

	>
		<HotkeyProvider>
			<IKContext 
			    publicKey={process.env.REACT_APP_IK_PUBLIC_KEY} 
			    urlEndpoint={process.env.REACT_APP_IK_ENDPOINT} 
			    authenticationEndpoint={process.env.REACT_APP_IK_AUTH_ENDPOINT} 
			 >
				<ApiProvider>
					<UserProvider>
						<ContentProvider>
							<BrowserRouter>	
								<Layout className='layout'>
									<AppHeader/>
									<Content >
										<Routes>
											<Route path="/explore" element={<Plants />}/>
											<Route path="/chat" element={<ChatPage />}/>
											<Route path="/enum/:fieldName" element={<EnumPage />}/>
											<Route path="*" element={<Navigate to='/explore' />}/>
										</Routes>
									</Content>
								</Layout>
							</BrowserRouter>
						</ContentProvider>
					</UserProvider>
				</ApiProvider>
			</IKContext>
		</HotkeyProvider>
	</ConfigProvider>;
}
