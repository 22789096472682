import React, { useContext, useEffect, useState } from 'react';
import { GardenAPI } from '../api';

const ApiContext = React.createContext(undefined);

export function ApiProvider(props) {
	const [ gardenAPI, setGardenAPI ] = useState();

	useEffect(() => {
		const url = process.env.REACT_APP_API_URL;
		new GardenAPI({ url }).connect().then(api => {
			api.createAccessor().then(accessor => {
				let garden = accessor.garden();
				setGardenAPI(garden)
			});
		})
	}, [])

	return <ApiContext.Provider value={{ gardenAPI }}>
		{props.children}
	</ApiContext.Provider>;
}

export function useApi() {
	const { gardenAPI } = useContext(ApiContext);
	return { gardenAPI };
}
