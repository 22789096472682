import { BloomPeriodField } from './bloomPeriod';
import { GalleryField } from './gallery';
import { DateField } from './date';
import { StringField, TextField } from './string';
import { EnumField } from './enum';

export const getFieldDataForType = (type) => {
	if (type.name === 'string') return StringField;
	if (type.name === 'text') return TextField;
	if (type.name === 'gallery') return GalleryField;
	if (type.name === 'enum') return EnumField;
	if (type.name === 'date') return DateField;
	if (type.name === 'bloomPeriod') return BloomPeriodField;
	return StringField;
}