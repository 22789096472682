import { PlusOutlined } from '@ant-design/icons'
import { useState, useRef, useEffect } from 'react'
import { Image, Button, Spin, Space } from 'antd';
import { IKImage, IKUpload } from 'imagekitio-react';

import { EyeOutlined, DeleteOutlined, UploadOutlined, FileImageOutlined } from '@ant-design/icons'
import strings from 'strings';


import './style.scss';

const ImageKit = require("imagekit-javascript");


export const ValueRender = ({ defaultValue, onChange }) => {

  const [ value, setValue ] = useState(defaultValue ?? []);
  const [ previewVisible, setPreviewVisible ] = useState(false);
  const [ currentPreview, setCurrentPreview ] = useState()

  const updateValue = (newValue) => {
    if (!onChange) return;
    onChange(newValue);
    setValue(newValue);
  }

  const deleteImage = (index) => {
    value.splice(index, 1)
    updateValue([...value]);
  }

  const addImage = (url) => {
    updateValue([...value.concat(url)]);
  }

  const onPaste = (e) => {
    return;
    if (!onChange) return;
    var imagekit = new ImageKit({
      publicKey: process.env.REACT_APP_IK_PUBLIC_KEY,
      urlEndpoint: process.env.REACT_APP_IK_ENDPOINT, 
      authenticationEndpoint: process.env.REACT_APP_IK_AUTH_ENDPOINT,
    });
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      imagekit.upload({
        file : fileObject,
        fileName : fileObject.name,
      }, function(err, result) {
        addImage(result.url);
      });
    } else {
      alert(
        "No image data was found in your clipboard. Copy an image first or take a screenshot."
      );
    }
  }

  return <>
    <div 
      className='gallery'
    >
      {value.map((url, index) => 
        <GalleryImage
          key={url}
          src={url}
          onDelete={onChange ? () => deleteImage(index) : undefined}
          onPreview={() => setCurrentPreview(index)}
        />
      )}
      {onChange && <Upload onUpload={url => addImage(url)}/>}
      {/*<div onPaste={e => onPaste(e)} style={{ zIndex: 100, backgroundColor: 'green', width: '50px', height: '50px' }}/>*/}
    </div>
    <div style={{ display: 'none' }}>
      <Image.PreviewGroup
        preview={{
          current: currentPreview,
          visible: currentPreview !== undefined,
          onVisibleChange: (v) => { 
            if (!v) setCurrentPreview(undefined) 
          },
        }}
      >
        {value ? value.map(url => <Image key={url} src={url}/>) : null}
      </Image.PreviewGroup>
    </div>
  </>
};

const Upload = ({ onUpload }) => {

  const [ ready, setReady ] = useState(true);

  const inputRefTest = useRef(null);

  const onError = (err) => {
    setReady(true)
  };

  const onSuccess = (res) => {
    onUpload(res.url);
    setReady(true)
  };

  const onUploadStart = (evt) => {
    setReady(false)
  }

  return <>
    <IKUpload
      onUploadStart={onUploadStart}
      onError={onError}
      onSuccess={onSuccess}
      style={{ display: 'none' }} // hide the default input and use the custom upload button
      inputRef={inputRefTest}
    />
    {ready && <Button icon={<UploadOutlined/>} onClick={() => inputRefTest.current.click()}/>}
    {!ready && <Spin size='large'/>}
  </>
}

const GalleryImage = ({ src, onDelete, onPreview }) => {
  const [ previewDisabled, setPreviewDisabled ] = useState(false)

  if (!src) return;

  const onPreviewClick = (e) => {
    onPreview();
    // e.stopPropagation();
  }

  const onDeleteClick = (e) => {
    onDelete();
    // e.stopPropagation();
  }

  return <div className='gallery-image'>
    <img 
      className='image' 
      src={src}
    />
    <div className='overlay'>
      <div className='overlay-fill'/>
      <div className='overlay-content' onClick={(e) => onPreviewClick(e)}>

        <div className='overlay-preview'>
          <EyeOutlined
            onClick={(e) =>  { if (!previewDisabled) onPreviewClick(e) } }
            className={`icon ${previewDisabled ? 'disabled' : ''}`}
            style={{ fontSize: '25px'}}
          />
        </div>
        {onDelete && <DeleteOutlined
          onMouseEnter={() => setPreviewDisabled(true)}
          onMouseLeave={() => setPreviewDisabled(false)}
          className={'overlay-delete'}
          style={{ fontSize: '20px'}}
          onClick={onDeleteClick}
        />}
      </div>
    </div>
  </div>
}

export const GalleryField = {
  render: ValueRender
}