import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Input, Button, Form, Checkbox } from 'antd';
import { useCookies } from 'react-cookie';
import { useApi } from '../api';
import strings from 'strings';
import './style.scss';

const UserContext = React.createContext(undefined);

function Login(props) {
	const [ username, setUsername ] = useState();
	const [ password, setPassword ] = useState();

	const onLogin = () => {
		props.onLogin(username, password);
	}

	return <div className='login-container'>
		<div className='login-form'> 
			<div className='login-form-bg'/>
			<img className='login-logo' src='https://ik.imagekit.io/f7wbs7axze/bg_logo_DdRDaOrcI.png'/>
			<p className='login-title'>{strings.brightGarden}</p>
			<Input  placeholder={strings.username} className='username' onChange={e => setUsername(e.target.value)}/>
			<Input.Password placeholder={strings.password} className='password' onChange={e => setPassword(e.target.value)}/>
			<Button type='primary' onClick={onLogin} className='login-button'>{strings.login}</Button>
		</div>
	</div>
}

export function UserProvider(props) {
	const [ cookies, setCookie, removeCookie ] = useCookies([ 'session' ]);
	const [ session, setSession ] = useState();
	const [ loggedIn, setLoggedIn ] = useState(false);
	const { gardenAPI } = useApi();

	useEffect(() => {
		if (loggedIn) return;
		if (!gardenAPI) return;
		if (!cookies.session) return;
		setLoggedIn(true);
		gardenAPI.setAccessParam('session', cookies.session);
		setSession(cookies.session)
	}, [ gardenAPI, cookies, loggedIn ])

	const logout = () => {
		setSession();
		removeCookie('session');
	}

	const login = (username, password) => {
		gardenAPI.login({ username, password }).then(res => {
			setLoggedIn(true)
			setSession(res.token);
			console.log(res)
			gardenAPI.setAccessParam('session', res.token);
			setCookie('session', res.token, {
				expires: new Date(res.expires),
				path: '/',
			});
		});
	}

	if (!gardenAPI) return <>No API</>
	if (!session) return <Login onLogin={login}/>;
	return <UserContext.Provider value={{ session, login }}>
		{props.children}
	</UserContext.Provider>;
}

export function useUser() {
	const { session, login, logout } = useContext(UserContext);
	return { session, login, logout };
}
