import { Input } from 'antd'; 

const StringRender = (props) => {
	if (!props.onChange) return <>{props.defaultValue}</>;
	return <Input 
		autoFocus={props.isFilter}
		onPressEnter={props.onPressEnter}
		defaultValue={props.defaultValue}
		onChange={props.onChange ? e => props.onChange(e.target.value) : undefined }
	/>
}

const TextRender = (props) => {
	if (!props.onChange) return <>{props.defaultValue}</>;
	return <Input.TextArea
		onPressEnter={props.onPressEnter}
		defaultValue={props.defaultValue}
		onChange={props.onChange ? e => props.onChange(e.target.value) : undefined }
	/>
}

export const StringField = {
	render: StringRender,
	filter: {
		eq: (value, filterValue) => {
			if (value === undefined) return false;
			if (filterValue === undefined) return true;
			return value.toLowerCase().includes(filterValue.toLowerCase());
		},
		render: StringRender,
	},
	sorter: (a, b) => {
		if (!a) a = '';
		if (!b) b = '';
		return a.localeCompare(b);
	},
}

export const TextField = {
	render: TextRender,
	filter: {
		eq: (value, filterValue) => {
			if (value === undefined) return false;
			if (filterValue === undefined) return true;
			return value.toLowerCase().includes(filterValue.toLowerCase());
		},
		render: StringRender,
	}
}
