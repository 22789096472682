import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
  ru: {
    edit:'Редактировать',
    apply: 'Принять',
    cancel: 'Отмена',
    delete: 'Удалить',
    copy: 'Копировать',
    deleteConfimation: 'Точно удалить?',
    addPlant: 'Добавить цветочек',
    plantFieldName: 'Название',
    plantFieldType: 'Вид',
    plantFieldBed: 'Клумба',
    plantFieldPhotos: 'Фотографии',
    plantFieldNotes: 'Заметки',
    plantFieldSource: 'Источник',
    plantFieldShadeTolerance: 'Теневыносливость',
    plantFieldPlantDate: 'Дата посадки',
    plantFieldBloomPeriod: 'Время цветения',
    actions: 'Действия',
    preview: 'Открыть',
    upload: '+',
    save: 'Сохранить',
    success: 'Успех',
    error: 'Ошибка',
    filter: 'Фильтр',
    clear: 'Очистить',
    login: 'Войти',
    username: 'Имя пользователя',
    password: 'Пароль',
    brightGarden: 'Bright Garden',
    newFlower: 'Новый цветочек',
    sort: 'Сортировка',
    sortAZ: 'Сортировка: А -> Я',
    sortZA: 'Сортировка: Я -> А',
    visibleFields: 'Видимые поля',
    explore: 'Сад',
    enums: 'Списки',
    fieldSetting: 'Настраиваем поле',
    chat: 'Чат с ботом',
    botTyping: 'Бот печатает',
    defaultBotMessage: 'Здравствуйте! Чем я могу помочь?',
    messagePlaceholder: 'Напишите что-нибудь боту',
    chatSend: 'Отправить',
    chatClear: 'Очистить чат',
  },
  en: {
    edit: 'Edit',
    apply: 'Apply',
    cancel: 'Cancel',
    delete: 'Delete',
    copy: 'Copy',
    deleteConfimation: 'Are you sure?',
    addPlant: 'Add plant',
    plantFieldName: 'Name',
    plantFieldType: 'Type',
    plantFieldBed: 'Flower bed',
    plantFieldPhotos: 'Photos',
    plantFieldNotes: 'Notes',
    plantFieldSource: 'Source',
    plantFieldShadeTolerance: 'Shade tolerance',
    plantFieldPlantDate: 'Plant date',
    plantFieldBloomPeriod: 'Bloom period',
    actions: 'Actions',
    preview: 'Preview',
    upload: '+',
    save: 'Save',
    success: 'Success',
    error: 'Error',
    filter: 'Filter',
    clear: 'Clear',
    login: 'Login',
    username: 'Username',
    password: 'Password',
    brightGarden: 'Bright Garden',
    newFlower: 'New flower',
    sort: 'Sort',
    sortAZ: 'Sort: A -> Z',
    sortZA: 'Sort: Z -> A',
    visibleFields: 'Visible fields',
    explore: 'Explore',
    enums: 'Enums',
    fieldSetting: 'Setting field',
    chat: 'Chat with bot',
    botTyping: 'Bot is typing',
    defaultBotMessage: 'Hello! How can I help you?',
    messagePlaceholder: 'Type something',
    chatSend: 'Send',
    chatClear: 'Clear chat',
  }
});
