import { useMemo } from 'react'
import { Select } from 'antd'; 
import { useContent } from 'contexts/content';

export const ValueRender = (props) => {
	const { enums } = useContent();

	let options = useMemo(() => {
		if (!enums) return;

		function onlyUnique(value, index, array) {
			return array.indexOf(value) === index;
		}
		let res = enums[props.field.type.enum].filter(onlyUnique).map(v => ({ label: v, value: v }));
		return [{ label: '-', value: '-' }].concat(res);
	}, [ enums, props.field.type ])

	if (!options) return; 

	if (!props.onChange) return props.defaultValue;

	return <Select 
		style={{ minWidth: 120 }}
		defaultOpen={props.isFilter}
		defaultValue={props.defaultValue ?? options[0].value}
		options={options}
		onChange={props.onChange}
		showSearch
	/>
}

export const EnumField = {
	render: ValueRender,
	filter: {
		eq: (value, filterValue) => value === filterValue,
		render: ValueRender,
	},
	sorter: (a, b) => {
		if (!a) a = '';
		if (!b) b = '';
		return a.localeCompare(b);
	},
	default: () => '-',
}
