import { useEffect, useState, useMemo } from 'react';
import { useApi } from 'contexts/api';
import { useContent } from 'contexts/content';
import { Table, Button, Input } from 'antd'; 
import { notif } from 'components/notification';
import strings from 'strings';
import schema from 'content';
import { HeaderCell } from './components/header';
import { Footer } from './components/footer';
import { useCookies } from 'react-cookie';

import './style.scss'

import { CopyOutlined, EditOutlined, SaveOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons'

export const Plants = () => {
	const [ visibleFields, setVisibleFields ] = useState(Object.fromEntries(schema.filter(f => !f.alwaysVisible).map(f => [ f.code, true ])));
	const [filter, setFilter] = useState({});
	const [sorter, setSorter] = useState({});
	const { gardenAPI } = useApi();
	const { plants, enums, reloadContent } = useContent();
	const [ editable, setEditable ] = useState();
	const [ inCreation, setInCreation ] = useState();
	
	const [cookies, setCookie, removeCookie] = useCookies([ 'pageSize', 'currentPage' ]);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	useEffect(() => {
		if (cookies['currentPage'])
			setCurrentPage(parseInt(cookies['currentPage']));
		if (cookies['pageSize'])
			setPageSize(parseInt(cookies['pageSize']));
	}, [ cookies ]);

	const enableEdit = (id) => {
		let plant = plants.find(plant => plant._id === id);
		setEditable({ ...plant })
	}

	const edit = (field, value) => {
		editable[field] = value;
	}

	const update = () => {
		let id = editable._id;
		delete editable._id;
		delete editable.key;
		if (Object.keys(editable).length > 0) {
			gardenAPI.plant(id).update(JSON.stringify(editable)).then(reloadContent);
		}
		setEditable();
	}

	const save = () => {
		gardenAPI.createPlant(editable).then(reloadContent);
		setEditable();
		setInCreation();
	}

	const cancel = () => {
		setEditable()
		setInCreation()
	}

	const remove = (id) => {
		if (window.confirm(strings.deleteConfimation)) {
			gardenAPI.plant(id).delete().then(reloadContent);
			setEditable();
		}
	}

	const defaultObject = {}
	for (let field of schema) {
		if (field.default) {
			defaultObject[field.code] = field.default();
		}
	}

	const create = (src) => {
		let obj = src ? { ...src } : { ...defaultObject };
		delete obj._id;
		delete obj.key;
		setEditable(obj);
		setInCreation(true);
	}

	const isEditing = (record) => editable && record._id === editable._id;

	const setFieldSorter = (fieldCode, value) => {
		if (value === undefined) {
			delete sorter[fieldCode];
		} else {
			sorter[fieldCode] = value;
		}
		console.log(sorter)
		setSorter({...sorter});
	}

	const setFieldFilter = (fieldCode, value) => {
		if (value === undefined) {
			delete filter[fieldCode];
		} else {
			filter[fieldCode] = value;
		}
		setFilter({...filter});
	}

	const onChangeVisibleFields = (v) => {
		setVisibleFields({...v})
	}

	let columns = [];
	for (let field of schema) {
		if (!field.alwaysVisible && !visibleFields[field.code]) continue;
		let headerProps = {
			key: `${field.code}.header`,
			field,
		}
		if (editable) {
			headerProps.compact = true;
		} else {
			if (field.filter) {
				headerProps.filter = {
					value: filter[field.code],
					onChange: (value) => setFieldFilter(field.code, value)
				}
			}
			if (field.sorter) {
				headerProps.sorter = {
					value: sorter[field.code],
					onChange: (value) => setFieldSorter(field.code, value)
				}
			}
		}
		let column = {
			// fixed: field.fixed,
			title: <HeaderCell
				{...headerProps}
			/>,
			dataIndex: field.code,
			render: (value, record) => <field.render
				defaultValue={value}
				field={field}
				onChange={isEditing(record) ? value => edit(field.code, value) : undefined}
			/>
		}
		columns.push(column)
	}

	if (!plants) return <>NO API</>;

	if (inCreation) {
		var data = [ editable ];
	} else {
		data = plants.map(p => ({ ...p, key: p._id }))
		for (let [ fieldCode, filterValue ] of Object.entries(filter)) {
			let field = schema.find(f => f.code === fieldCode);
			if (!field) continue;
			let filterData = field.filter;
			if (!filterData) continue;
			data = data.filter(plant => filterData.eq(plant[fieldCode], filterValue));
		};
		let sorts = Object.entries(sorter);
		if (sorts.length > 0) {
			data.sort((a, b) => {
				for (let [ fieldCode, sortOrder ] of sorts) {
					let field = schema.find(f => f.code === fieldCode);
					if (!field) continue;
					let sorter = field.sorter;
					if (!sorter) continue;
					let res = sorter(a[fieldCode], b[fieldCode]) * sortOrder;
					if (res !== 0) {
						return res;
					}
				}
			})
		}
	}

	var pagination = {
		current: currentPage,
		pageSize: pageSize,
		onChange: (current, pageSize) => {
			setCurrentPage(current);
			setCookie('currentPage', current, { path: '/' });
			setPageSize(pageSize);
			setCookie('pageSize', pageSize, { path: '/' });
		},
		showSizeChanger: true,
		total: data.length,
	};

	return <div>
		<Table
			bordered
			onRow={(record, rowIndex) => ({
				className: !editable ? 'row-active' : isEditing(record) ? 'row-editing' : 'row-disabled', 
				onClick: () => { 
					if (!editable) {
						enableEdit(record._id) 
					} else if (!isEditing(record)) {
						cancel();
					}
				}
			})}
			expandable={{
				expandedRowRender: (record) => <div className='editing'>
					{inCreation && <Button className='action-button' type='primary' icon={<SaveOutlined/>} onClick={() => save()}>{strings.save}</Button>}
					{!inCreation && <Button className='action-button' type='primary' icon={<SaveOutlined/>} onClick={() => update()}>{strings.apply}</Button>}
					<Button className='action-button' icon={<CloseCircleOutlined/>} onClick={() => cancel()}>{strings.cancel}</Button>
					{!inCreation && <Button className='action-button copy' icon={<CopyOutlined/> }onClick={() => create(record)}>{strings.copy}</Button>}
					{!inCreation && <Button className='action-button delete' icon={<DeleteOutlined/> }onClick={() => remove(record._id)}>{strings.delete}</Button>}
				</div>,
				rowExpandable: (record) => isEditing(record),
				showExpandColumn: false,
				expandedRowKeys: editable ? [ editable._id ] : [],
			}}
			className='plants-table'
			scroll={{
				x: 'max-content'
			}}
			dataSource={data} 
			columns={columns}
			pagination={pagination}
			title={() => <Footer 
				onChangeVisibleFields={onChangeVisibleFields}
				visibleFields={visibleFields}
				compact={editable}
				onNewPlant={() => create()}
			/>}
			footer={() => <Footer 
				onChangeVisibleFields={onChangeVisibleFields}
				visibleFields={visibleFields}
				compact={editable}
				onNewPlant={() => create()}
			/>}
		/>
	</div>;
}