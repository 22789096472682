import { useState, useEffect } from 'react';
import { useContent } from 'contexts/content';
import { useParams } from 'react-router-dom';
import { notif } from 'components/notification';
import { Input, Button, Space } from 'antd';
import { useApi } from 'contexts/api'
import strings from 'strings';
import schema from 'content'

import './style.scss';

const { TextArea } = Input;

export const EnumPage = () => {
	const { gardenAPI } = useApi();
	const { fieldName } = useParams();
	const { enums, reloadContent } = useContent();
	const [ value, setValue ] = useState();
	const [ field, setField ] = useState();

	useEffect(() => {
		if (!fieldName) return;
		if (!enums) return;
		let f = schema.find(f => f.code == fieldName);
		setValue([...enums[f.type.enum]].sort().join('\n'));
		setField(f);
	}, [ enums, fieldName ])

	const save = () => {
		gardenAPI.enum(field.type.enum).update(value).then(res => {
			reloadContent();
			notif.success(strings.success, undefined, undefined, 1);
		}).catch(e => {
			notif.error(strings.error, e.message);
		});
	}

	if (field === undefined) return;

	return <div className='enum-container'>
		<h2>{strings.fieldSetting}: {strings[field.title]}</h2>
		<TextArea 
			className='enum-input'
			key={field.code}
			defaultValue={value}
			onChange={e => setValue(e.target.value)}
		/>
		<Button type='primary' onClick={() => save()}>{strings.save}</Button>
	</div>
}