import { getFieldDataForType } from './fields';
import strings from 'strings';

const schema = [
	{
		code: 'name',
		title: 'plantFieldName',
		type: {
			name: 'string'
		},
		alwaysVisible: true,
		fixed: 'left',
		default: () => strings.newFlower,
	},
	{
		code: 'bed',
		title: 'plantFieldBed',
		type: {
			name: 'enum', 
			enum: 'beds'
		}
	},
	{
		code: 'photos',
		title: 'plantFieldPhotos',
		type: {
			name: 'gallery'
		}
	},
	{
		code: 'type',
		title: 'plantFieldType',
		type: {
			name: 'enum', 
			enum: 'types'
		}
	},
	{
		code: 'shadeTolerance',
		title: 'plantFieldShadeTolerance',
		type: {
			name: 'enum',
			enum: 'shadeTolerance'
		}
	},
	{
		code: 'source',
		title: 'plantFieldSource',
		type: {
			name: 'string',
		}
	},
	{
		code: 'notes',
		title: 'plantFieldNotes',
		type: {
			name: 'text',
		}
	},
	{
		code: 'plantDate',
		title: 'plantFieldPlantDate',
		type: {
			name: 'date'
		}
	},
	{
		code: 'bloomPeriod',
		title: 'plantFieldBloomPeriod',
		type: {
			name: 'bloomPeriod',
		}
	}
]

for (let field of schema) {
	Object.assign(field, getFieldDataForType(field.type))
}

export default schema;
