import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import strings from 'strings';
import { useState, useEffect } from 'react'
import { InputNumber, Select } from 'antd';
import * as dayjs from 'dayjs'
import './style.scss';

const months = {
    march: {
    	number: 2,
    	days: 31,
    },
    april: {
    	number: 3,
    	days: 30,
    },
    may: {
    	number: 4,
    	days: 31,
    },
    june: {
    	number: 5,
    	days: 30,
    },
    july: {
    	number: 6,
    	days: 31,
    },
    august: {
    	number: 7,
    	days: 31,
    },
    september: {
    	number: 8,
    	days: 30,
    },
    october: {
    	number: 9,
    	days: 31,
    }
};

const selectOptions = Object.entries(months).map(([ month, monthData ]) => ({
	value: month,
	label: dayjs().month(monthData.number).format('MMMM'),
}));

const dayToDate = (day) => {
	day++;
	let monthArray = Object.keys(months)
	while (monthArray.length > 0 && day > months[monthArray[0]].days) {
		day -= months[monthArray.shift()].days;
	}
	if (monthArray.length == 0) {
		throw 'ERR'; // TODO
	}
	return {
		month: monthArray[0],
		day,
	}
}

const dateToDay = (date) => {
	let monthArray = Object.keys(months);
	let day = 0;
	while (monthArray.length > 0 && monthArray[0] !== date.month) {
		day += months[monthArray.shift()].days;
	}
	if (monthArray.length == 0) {
		throw 'ERR'; // TODO
	}
	return day + date.day;
}


const defaultValue = [
	{
		month: 'march',
		day: 1
	},
	{
		month: 'april',
		day: 30
	}
]

const rangeSize = Object.values(months).reduce((acc, month) => acc + month.days, 0);

const Slider = (props) => {
	const onInput = ([ from, to ]) => {
		props.onChange([ dayToDate(from), dayToDate(to) ])
	}

	let startDay = dateToDay(props.defaultValue[0]);
	let endDay = dateToDay(props.defaultValue[1]);

	return <div key={props.revision} className='bloom-picker-base'>
		<div className='months'>
			{Object.values(months).map((m, index) => <div 
				key={index}
				className='month'
				// className={`month${index < value[0] || index > value[1] ? ' disabled' : ''}`}
			>
				{dayjs().month(m.number).format('MMM')}
			</div>)}
		</div>
		<RangeSlider 
			defaultValue={[startDay, endDay]}
			min={0}
			max={rangeSize - 1}
			className='bloom-picker'
			onInput={onInput}
		/>
	</div>
}

const Selector = (props) => {
	const [ value, setValue ] = useState(props.defaultValue);
	const [ revision, setRevision ] = useState(1);

	useEffect(() => {
		setValue(props.defaultValue)
	}, [ props.revision ])

	const onChange = (valueType, index, v) => {
		value[index][valueType] = v;
		if (valueType === 'month') {
			if (value[index].day > months[v].days) {
				value[index].day = months[v].days;
				setRevision(3 - revision);
			}
		}
		props.onChange(value);
	}

	return <div key={`${props.revision}.${revision}`} className='bloom-picker-selector'>
		<Select 
			className='bloom-picker-selector-month'
			defaultValue={value[0].month} 
			options={selectOptions}
			onChange={value => onChange('month', 0, value)}
		/>
		<InputNumber
			style={{ width: 50 }}
			min={1} 
			max={months[value[0].month].days} 
			defaultValue={value[0].day}
			onChange={value => onChange('day', 0, value)}
		/>
		<div className='bloom-picker-selector-between'>-</div>
		<Select 
			className='bloom-picker-selector-month'
			defaultValue={value[1].month}
			options={selectOptions}
			onChange={value => onChange('month', 1, value)}
		/>
		<InputNumber 
			style={{ width: 50 }}
			min={1} 
			max={months[value[1].month].days}
			defaultValue={value[1].day}
			onChange={value => onChange('day', 1, value)}
		/>
	</div>
}

const getStringDate = (date) => {
	return `${date.day} ${dayjs().month(months[date.month].number).format('MMMM')}`;
}


const ValueRender = (props) => {
	const [ sliderRevision, setSliderRevision ] = useState(1);
	const [ selectorRevision, setSelectorRevision ] = useState(1);
	const [ value, setValue ] = useState(props.defaultValue ?? defaultValue); // TODO: props.defaultValue

	if (!props.onChange) return <>{getStringDate(value[0])} - {getStringDate(value[1])} </>

	const onChangeSelector = ([from, to ]) => {
		value[0] = from;
		value[1] = to;
		props.onChange(value)
		setSliderRevision(3 - sliderRevision)
	}

	const onChangeSlider = ([from, to ]) => {
		value[0] = from;
		value[1] = to;
		props.onChange(value)
		setSelectorRevision(3 - selectorRevision)
	}

	return <>
		<Selector revision={selectorRevision} defaultValue={value} onChange={onChangeSelector}/>
		<Slider revision={sliderRevision} defaultValue={value} onChange={onChangeSlider}/>
	</>
}



export const BloomPeriodField = {
	render: ValueRender,
	default: () => {
		return [
			{
				month: 'may',
				day: 10,
			},
			{
				month: 'june',
				day: 10,
			}
		]
	}
}