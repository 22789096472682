import { Button, Select, Popover, Switch, Space } from 'antd';
import strings from 'strings';
import { PlusCircleOutlined, GroupOutlined } from '@ant-design/icons'
import { useState } from 'react';
import schema from 'content';

const FieldSwitch = (props) => {
	return <Space className='field-switch'>
		<Switch defaultChecked={props.defaultValue} onChange={props.onChange}/>
		<div>{props.title}</div>
	</Space>
}

const FieldSelector = (props) => {
	const [ value, setValue ] = useState(props.defaultValue);

	const onFieldChange = (fieldCode, v) => {
		value[fieldCode] = v;
		props.onChange(value)
	}

	return <Space direction='vertical'>
		{schema.filter(f => !f.alwaysVisible).map(f => <FieldSwitch
			key={f.code}
			title={strings[f.title]}
			onChange={v => onFieldChange(f.code, v)}
			defaultValue={value[f.code]}
		/>)}
		<Button onClick={props.onClose} type='primary'>{strings.apply}</Button>
	</Space>
}

export const Footer = (props) => {
	const [ fieldSelectorVisible, setFieldSelectorVisible ] = useState();

	return <div className='table-footer'>
		{!props.compact && <Button className='action-button' icon={<PlusCircleOutlined />} onClick={props.onNewPlant}>{strings.addPlant}</Button>}
		{!props.compact && <div className='field-selector-container'>
			<Popover 
				open={fieldSelectorVisible} 
				content={<FieldSelector 
					defaultValue={props.visibleFields}
					onChange={props.onChangeVisibleFields}
					onClose={() => setFieldSelectorVisible(false)}
				/>}
				trigger='click'
				onOpenChange={() => setFieldSelectorVisible(!fieldSelectorVisible)}
				title={strings.visibleFields}
			>
	    		<Button icon={<GroupOutlined/>} onClick={() => setFieldSelectorVisible(true)}>{strings.visibleFields}</Button>
	 	 	</Popover>
 	 	</div>}


		
	</div>
}